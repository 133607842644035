.hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../images/landingpage-bg.webp');
  background-size: cover;
  background-position: center;
}

.hero__container {
  width: 100%;
  max-width: 800px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero__container h1 {
  font-size: 4rem;
  color: #fff;
  margin-bottom: 20px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.hero__container p {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 40px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.hero__container button {
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero__container button:hover {
  background-color: #f0f0f0;
}
