.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header .MuiToolbar-root {
  background-color: #001609;
  color: rgb(181, 201, 181);
}

body {
  background-color: #1f043d !important; /* Set the background color */
  color: rgb(216, 174, 255) !important; /* Set the text color */
  font-family: Roboto; /* Set the font */
}

a {
  color: #ff69b4;
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
  }
}

.hero-content {
  text-align: center;
  padding: 100px 20px;
  background-color: #1a1a1a00;
  color: #eee; /* Light text */
}

.hero-content h1 {
  font-size: 3em;
  margin-bottom: 10px;
  text-shadow: 0 0 5px #555; /* Subtle text shadow */
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Style the button - use your preferred method */
.hero-content .MuiButton-containedPrimary {
  background-color: #9c724f; /* Example goblin-y color */
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a shadow */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.page-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}
